import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import Link from "../components/addon/Link"

const Philanthrophy = ({ data: { allNodeTemplatePages: { edges } } }) => {

    const rawData = edges[0]
    const metaTags = rawData.node?.metatag_normalized.length > 0 ? rawData?.node?.metatag_normalized : [];
    const schema = rawData?.node?.relationships?.field_seo_schema ? rawData?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = rawData.node?.metatags_on_page || {}
    const data = rawData?.node?.relationships?.components;

    const [bannerData, commitmentData, hospitalCharityData, servePassionData, saveLivesData] = data
    // commitmentSection
    const commitmentTitle = commitmentData?.relationships?.components[0]?.field_title || ''
    const commitmentDescription = commitmentData?.relationships?.components[0]?.text?.processed || ''
    // // giveHopeSection
    // const giveHopeTitle = giveHopeData?.relationships?.components[0]?.title || ''
    // const giveHopeImg = giveHopeData?.relationships?.components[0]?.relationships?.image?.uri?.url ? giveHopeData?.relationships?.components[0]?.relationships?.image?.uri?.url : '/assets/images/philanthrophy/live-to-go-hope-thumb.png'
    // const giveHopeImgAlt = giveHopeData?.relationships?.components[0]?.image?.alt || 'Live to Give Hope Thumb'
    // const giveHopeDescription = giveHopeData?.relationships?.components[0]?.description?.processed || ''
    // const giveHopeBtnData = giveHopeData?.relationships?.components[0] || ''
    // hospitalCharitySection
    const hospitalCharityTitle = hospitalCharityData?.relationships?.components[0]?.title || ''
    const hospitalCharityImg = hospitalCharityData?.relationships?.components[0]?.relationships?.image?.uri?.url ? hospitalCharityData?.relationships?.components[0]?.relationships?.image?.uri?.url : '/assets/images/philanthrophy/charity-at-hospital-thumb.png'
    const hospitalCharityImgAlt = hospitalCharityData?.relationships?.components[0]?.image?.alt || 'Charity at the Hospital'
    const hospitalCharityDescription = hospitalCharityData?.relationships?.components[0]?.description?.processed || ''
    const hospitalCharityBtnTitle = hospitalCharityData?.relationships?.components[0]?.link?.title || ''
    const hospitalCharityBtnLink = hospitalCharityData?.relationships?.components[0]?.link?.uri || ''
    // servePassionSection
    const servePassionTitle = servePassionData?.relationships?.components[0]?.field_title || ''
    const servePassionDescription = servePassionData?.relationships?.components[0]?.text?.processed || ''
    const servePassionTitle2 = servePassionData?.relationships?.components[1]?.field_title || ''
    const servePassionBtnTitle = servePassionData?.relationships?.components[1]?.field_link?.title || ''
    const servePassionBtnLink = servePassionData?.relationships?.components[1]?.field_link?.uri || ''
    const servePassionCardsArr = servePassionData?.relationships?.components[1]?.relationships?.field_components || []
    // saveLivesSection
    const saveLivesTitle = saveLivesData?.relationships?.components[0]?.field_title || ''
    const saveLivesDescription = saveLivesData?.relationships?.components[0]?.text?.processed || ''

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/homepage.css","/assets/css/philanthrophy.css", "/assets/css/accessibility.css"],
                    }}
                    tags={{metaTags,canonicalUrl}}
                />
                <Schema schema={schema} />
                <main className="innerpage">
                    <section className="section_bg pt-first-section pb-section philanthrophy-section">
                        <div className="container">
                        <div className="para_text pb-4">
                            <h2>{commitmentTitle}</h2>
                            <div dangerouslySetInnerHTML={{ __html: commitmentDescription }} ></div>
                        </div>
                        {/* <div className="live-to-hope-section">
                            <div className="top-content">
                            <h2>{giveHopeTitle}</h2>
                            </div>
                            <div className="row">
                            <div className="col-lg-4 col-md-5">
                                <img src={giveHopeImg} alt={giveHopeImgAlt} />
                            </div>
                            <div className="col-lg-6 col-md-7">
                                <div className="para_text">
                                    <div dangerouslySetInnerHTML={{ __html: giveHopeDescription }} ></div>
                                    <Link classes="btn btn-primary" data={giveHopeBtnData}/>
                                </div>  
                            </div>  
                            </div>  
                        </div> */}
                        <div className="charity-hospital-section">
                            <div className="row flex-row-reverse">
                            <div className="col-md-5">
                                <div className="img-section">
                                    <img src={hospitalCharityImg} alt={hospitalCharityImgAlt} />
                                </div>  
                            </div>
                            <div className="col-md-7">
                                <div className="para_text">
                                    <h2>{hospitalCharityTitle}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: hospitalCharityDescription }} ></div>
                                    <Link to={hospitalCharityBtnLink} className="btn btn-primary">{hospitalCharityBtnTitle}</Link>
                                </div>
                            </div>  
                            </div>  
                        </div>  
                        <div className="philanthrophy-cards">
                            <div className="para_text pb-4">
                                <h2>{servePassionTitle}</h2>
                                <div dangerouslySetInnerHTML={{ __html: servePassionDescription }} ></div>
                                <p><strong>{servePassionTitle2}</strong></p>
                            </div>  
                            <div className="row">
                                {servePassionCardsArr?.length 
                                ? servePassionCardsArr.map(card => {
                                    let cardImg = card?.relationships?.image?.uri?.url || '/assets/images/philanthrophy/primery-healthcare-thumb.png'
                                    let cardImgAlt = card?.image?.alt || 'Primery Health Care Thumb'
                                    let cardDescription = card?.description?.processed || ''
                                    return (
                                        <div className="col-lg-4 col-md-6 cardWrap">
                                            <div className="card"> 
                                                <img src={cardImg} alt={cardImgAlt} />
                                                <div className="card-body" dangerouslySetInnerHTML={{ __html: cardDescription }}>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : null}
                                <div className="col-lg-12 col-md-12">
                                    <div className="btnWrap">
                                        <Link to={servePassionBtnLink} className="btn btn-primary">{servePassionBtnTitle}</Link>
                                    </div>  
                                </div>  
                            </div>
                        </div>
                        <div className="save-lives-wrap">
                            <div className="para_text pb-4">
                                <h2>{saveLivesTitle}</h2>
                            </div>
                            <div className="para_text pb-4" dangerouslySetInnerHTML={{ __html: saveLivesDescription }}>
                            </div>
                        </div>  
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}

export const query = graphql`
query philanthrophy {

    allNodeTemplatePages(filter: {path: {alias: {regex: "/philanthrophy$/"}}}) {

    edges {

    node {

    id

    title

    path {

    alias

    }
    relationships{
        field_seo_schema{
            field_seo_title
            field_seo_schema_text_area
        }
    }
    metatag_normalized {

    attributes {

    content

    name
    property

    }

    }
    metatags_on_page: field_meta_tags{
        canonical_url
      }
      
    relationships {

    components: field_component_type {

    __typename

    ...phParagraphSection

    }

    }

    }

    }

    }

    }

   

    

    

    fragment phParagraphSection on paragraph__section {

    id

    name: field_name

    relationships {

    components: field_components {

    __typename

    ...phParagraphBanner

    ...phParagraphTitleAndDescription

    ...philParagraphImageTitleDescLink

    ... on paragraph__title_and_description_components {

    id

    field_name

    field_title

    field_subtitle

    field_link {

    uri

    title

    }

    relationships {

    field_components {

    ...phParagraphImageWithTitleAndDescription

    ...philParagraphImageTitleDescLink

    }

    }

    }

    }

    }

    }

   

    

    

    fragment phParagraphBanner on paragraph__banner {

     id

  title: field_title

  subtitle: field_subtitle

  bannerImage: field_banner_image {

    alt

  }

  mobileBannerImage: field_mobile_banner {

    alt

  }

  bannerLink: field_banner_link {

    uri

    title

  }

  relationships {

    bannerImage: field_banner_image {

      id

      uri {

        value

        url

      }

    }

    mobileBannerImage: field_mobile_banner {

      id

      uri {

        url

        value

      }

    }

  }

    }

   

    

    

    fragment phParagraphTitleAndDescription on paragraph__title_and_description {

    id

    field_title

    text: field_description {

    processed

    }

    field_cta_link {

    title

    uri

    }

    field_text {

    processed

    }

    }

   

    

    

    fragment philParagraphImageTitleDescLink on paragraph__image_title_desc_link {

    id

    title: field_title

    title1: field_title_1

    description: field_description {

    processed

    }

    link: field_link {

    uri

    title

    }

    image: field_image {

    alt

    }

    relationships {

    image: field_image {

    id

    uri {

    value

    url

    }

    }

    }

    }

   

    

    

    fragment phParagraphImageWithTitleAndDescription on paragraph__image_with_title_and_description {

    id

    title: field_title

    description: field_content {

    processed

    }

    image: field_image {

    alt

    }

    alignment: field_alignment

    relationships {

    image: field_image {

    id

    uri {

    value

    url

    }

    }

    }

    }
`
export default Philanthrophy